import React, {Dispatch} from "react";

const SelfReportingConfirmation = ({showConfirmation}:{showConfirmation:Dispatch<React.SetStateAction<any>>}) => {
    return (
        <div className={"form-submission-container"}>
            <h2>Results submitted</h2>
            <p>Thank you for submitting your lateral flow test result. You will receive a confirmation via email to the email address provided shortly.</p>
            <button onClick={() => showConfirmation(false)} className={"selfReportingConfirmationButton"}>Click here to submit another result.</button>
        </div>
    )
}

export default SelfReportingConfirmation;