

const Footer = () => {
    return (
        <footer>
            <section className={'footer-address'}>
                <p><b>Office Address</b></p>
                <p>20-22 Wenlock Road,</p>
                <p>London,</p>
                <p>N1 7GU</p>
            </section>
            <p className={'footer-copyright'}>© Speedy Checks Ltd 2022</p>
        </footer>
    )
}

export default Footer;