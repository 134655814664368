export interface FAQ {
    question: string,
    answer: string | string[],
}

const FAQs: FAQ[] = [
    {
        question: "Do I need to report the result of the test to the NHS?",
        answer: [
            "The NHS portal does not accept results/QR coes from non-NHS supplied tests.  Users of the Speedycheckes test can report test results to UKHSA (formally PHE).",
            "Please contact the speedychecks customer care team on 0208 108 2630 who can assist you.",
        ],
    },
    {
        question: "Why is there no line showing or a faint line?",
        answer: [
            "Please ensure that 2-3 generous drops of solution are added to the sample well. Once a sufficient amount of fluid has been added the control line should become visible.",
            "Please ensure the test kit is stored at 4-30°C and use the test card within 1 hour once the foil pouch is opened.",
        ],
    },
    {
        question: "Will I get a certificate?",
        answer: "Users will NOT receive a certificate of results.  You can report your result to UKHSA if you wish by contacting the Speedychecks customer care team on 0208 108 2630.",
    },
    {
        question: "What is the sensitivity of the test?",
        answer: "The sensitivity and specificity of our test is 97.06 % and 98.81 %. The test has 5 year COVID Testing Devices Authorisation (CTDA) approval from UK's Health Security Agency (UKHSA).",
    }
];

export default FAQs;
