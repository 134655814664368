import SpeedyChecksLogo from "../../assets/SpeedyChecksLogo_02.svg";

const Navigation = () => {

    return (
        <header>
            <img className={"header-logo"} src={SpeedyChecksLogo} alt={"Speedy Checks Logo"}/>
        </header>
    )
}

export default Navigation;