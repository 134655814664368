import UcLogo from "../../assets/uc_logo.png";
import Button from "../../components/Button/Button";
import FAQBlock from "../../components/FAQ/FAQ";
import FAQs from "../../data/FAQs";

const HomePage = () => {

    return (
        <div className={"uc-container"}>
            <div id="uc-logo">
                <img src={UcLogo} className="uc-centre-logo" alt="Speedychecks Logo"/>
            </div>
            <div id="uc-main">
                <h2>
                    Providers of COVID-19 test kits
                </h2>
                <Button href={"/self_reporting"} label={"Report a lateral flow test result"}/>
                <h2><a href="tel:+442081082630">0208 108 2630</a></h2>
                <h2><a href="mailto:customercare@speedychecks.co.uk">customercare@speedychecks.co.uk</a></h2>
                <h3>Not suitable for travel testing</h3>
                <p>Registered office address: 20-22 Wenlock Road, London N1 7GU, England, United Kingdom</p>
            </div>
            <div id="faqs">
                {FAQs.map((faq) => <FAQBlock {...faq} /> )}
            </div>
        </div>
    )
}

export default HomePage;