import React from 'react';
import './App.scss';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import MainLayout from "./MainLayout";
import SelfReporting from "../selfReporting/SelfReporting";
import HomePage from "../../pages/Home/Home";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>

                <Route path={"/self_reporting"} element={<MainLayout />}>
                    <Route path={"/self_reporting"} element={<SelfReporting />} />
                </Route>

                <Route path={"/"} element={<HomePage />} />
                
                <Route path={"*"} element={<Navigate to={"/"} replace />}/>

            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
