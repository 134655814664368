import SelfReportingForm from "./SelfReportingForm";
import SelfReportingConfirmation from "./SelfReportingConfirmation";
import {useState} from "react";

const SelfReporting = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <div id={"selfReportingContainer"}>
            {showConfirmation ? <SelfReportingConfirmation showConfirmation={setShowConfirmation}/>  : <SelfReportingForm showConfirmation={setShowConfirmation}/>}
        </div>
    )
}

export default SelfReporting;