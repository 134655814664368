import {MouseEventHandler} from "react";

import {Link} from "react-router-dom";

import styles from "./Button.module.scss";

interface ButtonInterface {
    label: string;
}

interface ButtonWithLink extends ButtonInterface {
    href: string;
    onClick?: never;
}

interface ButtonWithClick extends ButtonInterface {
    onClick: MouseEventHandler<HTMLButtonElement>;
    href?: never;
}

type ButtonProps = ButtonWithLink | ButtonWithClick

const Button = (props: ButtonProps) => {
    const {label, onClick, href} = props;

    if (href) return (
        <Link to={href} className={styles.Button}>
            {label}
        </Link>
    );

    return (
        <button onClick={onClick} className={styles.Button}>
            {label}
        </button>
    );
}

export default Button;
