import {useForm, Controller} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import React, {Dispatch, useState} from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

interface selfReportingForm {
    firstName: string;
    lastName: string;
    sexAtBirth: string;
    dateOfBirth: Date | null;
    nhsNumber?: string;
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    town: string;
    county?: string;
    postcode: string;
    ethnicity: string;
    phoneNumber: string;
    email: string;
    result: string;
    specimenID: string;
    specimenDate: Date | null;
    specimenType: string;
    testRequestingOrganisation: string;
    manufacturerOfTest: string;
    affiliate?: string;
}

enum SpecimenType {
    Saliva = 1,
    Blood,
    Tissue,
    NasopharyngealSwab,
    Nose ,
    Throat,
    NoseThroat,
    Mouth,
    UpperRespiratory,
    Other
}

const SelfReportingForm = ({showConfirmation}:{showConfirmation:Dispatch<React.SetStateAction<any>>}) => {
    const [recaptchaValid, setRecaptchaValid] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const {register, handleSubmit, control, setError, clearErrors, formState: {errors, isValid}} = useForm({
        mode: "onChange",
        defaultValues: {
            firstName: "",
            lastName: "",
            sexAtBirth: "",
            dateOfBirth: null,
            nhsNumber: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            town: "",
            county: "",
            postcode: "",
            ethnicity: "",
            phoneNumber: "+44",
            email: "",
            result: "",
            specimenID: "",
            specimenDate: null,
            specimenType: "",
            testRequestingOrganisation: "",
            manufacturerOfTest: "speedyChecks",
            submitError: ""
        }
    })

    const errorField = (fieldMessage:string|undefined) => {
        return <p className={"errorField"}><FontAwesomeIcon icon={faCircleInfo} /> {fieldMessage}</p>;
    }

    const phoneNumberValidation = (value:any) => {
        if(!(typeof value === "string" || value instanceof String))return 'A valid Phone number is required';
        if(!String(value).startsWith('+'))return 'Must start with a +';
        if(!(/^(\+\d{1,3})/.test(String(value))))return 'Must start with a valid country code';
        if(String(value).match(/[^+\d]/))return "Must contain only numbers and a '+'";
        if(!(String(value).length > 3)) return 'A valid Phone number is required';
        if(String(value).length > 20) return 'Too many characters';
        return true;
    }

    const validateDateOfBirth = (value:any) => {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        if (value >= currentDate) return 'Must be in the past'
        return true;
    }

    const submit = async (form: selfReportingForm) => {
        setSubmitting(true);
        clearErrors();
        const url = process.env.REACT_APP_SUBMIT_API_URL ?? '';
        const config = {
            headers: { Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8", }
        }

        form.affiliate = "Speedy Checks";

        try{
            const response = await axios.post(url, JSON.stringify(form), config);
            console.log(response.data);
            if(response.data.success)showConfirmation(true);
            else setError("submitError", {type: "focus", message: "An error occurred. Please try again."}, {shouldFocus: true})
        }catch (e) {
            console.error(e);
            setError("submitError", {type: "focus", message: "An error occurred. Please try again."}, {shouldFocus: true})
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <>
            <div id={"selfReportingTitleContainer"}>
                <h2>Report a Lateral Flow Test Result</h2>
                <p>Required fields are marked with a *</p>
            </div>
            <form className={"selfReportingForm"} onSubmit={
                handleSubmit(async (form:selfReportingForm) => {
                await submit(form);}, () => {
                    if(errors.submitError)clearErrors("submitError");
                })}>
                <div className={"column firstColumn"}>
                    <section>
                        <label>First Name*</label>
                        <input {...register("firstName", {required: 'First Name is a required field', pattern: {value:/^([a-zA-Z ']*)$/, message: "Invalid Characters - Only Letters"}, maxLength: {value: 99, message: "Too many characters"}})} className={errors.firstName && 'inputError'} />
                        {errors.firstName && errorField(errors.firstName.message)}
                    </section>

                    <section>
                        <label>Last Name*</label>
                        <input {...register("lastName", {required: 'Last Name is a required field', pattern: {value:/^([a-zA-Z ']*)$/, message: "Invalid Characters - Only Letters"}, maxLength: {value: 37, message: "Too many characters"}})} className={errors.lastName && 'inputError'} />
                        {errors.lastName && errorField(errors.lastName.message)}
                    </section>

                    <section>
                        <label>Sex At Birth*</label>
                        <select {...register("sexAtBirth", {required: 'Sex At Birth is a required field'})} className={errors.sexAtBirth && 'inputError'}>
                            <option value={"male"}>Male</option>
                            <option value={"female"}>Female</option>
                            <option value={"other"}>Prefer not to say</option>
                        </select>
                        {errors.sexAtBirth && errorField(errors.sexAtBirth.message)}
                    </section>

                    <section>
                        <label>Date Of Birth*</label>
                        <Controller
                            control={control}
                            name="dateOfBirth"
                            rules={{required: 'Date of Birth is a required field', validate: validateDateOfBirth}}
                            render={({field}) => (
                                <ReactDatePicker
                                    className={errors.dateOfBirth && 'inputError'}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={field.onChange}
                                    selected={field.value}
                                />
                            )}
                        />
                        {errors.dateOfBirth && errorField(errors.dateOfBirth.message)}
                    </section>

                    <section>
                        <label>NHS Number</label>
                        <input {...register("nhsNumber")} />
                    </section>

                    <section>
                        <label>Address Line 1*</label>
                        <input {...register("addressLine1", {required: 'Address Line 1 is a required field', maxLength: {value: 34, message: 'Too many characters'}})} className={errors.addressLine1 && 'inputError'} />
                        {errors.addressLine1 && errorField(errors.addressLine1.message)}
                    </section>

                    <section>
                        <label>Address Line 2</label>
                        <input {...register("addressLine2", {maxLength: {value: 34, message: 'Too many characters'}})} className={errors.addressLine2 && 'inputError'} />
                        {errors.addressLine2 && errorField(errors.addressLine2.message)}
                    </section>

                    <section>
                        <label>Address Line 3</label>
                        <input {...register("addressLine3", {maxLength: {value: 34, message: 'Too many characters'}})} className={errors.addressLine3 && 'inputError'} />
                        {errors.addressLine3 && errorField(errors.addressLine3.message)}
                    </section>

                    <section>
                        <label>City / Town*</label>
                        <input {...register("town", {required: 'Town is a required field', maxLength: {value: 34, message: 'Too many characters'}})} className={errors.town && 'inputError'} />
                        {errors.town && errorField(errors.town.message)}
                    </section>

                    <section>
                        <label>County</label>
                        <input {...register("county", {maxLength: {value: 50, message: 'Too many characters'}})} className={errors.county && 'inputError'} />
                        {errors.county && errorField(errors.county.message)}
                    </section>

                    <section>
                        <label>Postcode*</label>
                        <input {...register("postcode", {required: 'Postcode is a required field', maxLength: {value: 10, message: 'Too many characters'}})} className={errors.postcode && 'inputError'} />
                        {errors.postcode && errorField(errors.postcode.message)}
                    </section>
                </div>


                <div className={"column secondColumn"}>
                    <section>
                        <label>Ethnicity*</label>
                        <select {...register("ethnicity", {required: 'Ethnicity is a required field'})} className={errors.ethnicity && 'inputError'}>
                            <option value={"1"}>Any Other Ethnic Category</option>
                            <option value={"2"}>Any Other Mixed Group</option>
                            <option value={"3"}>Arab</option>
                            <option value={"4"}>Asian British</option>
                            <option value={"5"}>Asian Other</option>
                            <option value={"6"}>Bangladeshi</option>
                            <option value={"7"}>Black - African</option>
                            <option value={"8"}>Black - Caribbean</option>
                            <option value={"9"}>Black - Other</option>
                            <option value={"10"}>Chinese</option>
                            <option value={"11"}>Indian</option>
                            <option value={"12"}>ISC - Unspecified</option>
                            <option value={"13"}>Other / Mixed</option>
                            <option value={"14"}>Pakistani</option>
                            <option value={"15"}>Unknown</option>
                            <option value={"16"}>White</option>
                            <option value={"17"}>White And Asian</option>
                            <option value={"18"}>White And Black African</option>
                            <option value={"19"}>White And Black Caribbean</option>
                            <option value={"20"}>White British</option>
                            <option value={"21"}>White Irish</option>
                            <option value={"22"}>White Other</option>
                            <option value={"23"}>Prefer Not To Say</option>
                        </select>
                        {errors.ethnicity && errorField(errors.ethnicity.message)}
                    </section>

                    <section>
                        <label>Phone Number*</label>
                        <input {...register("phoneNumber", {required: 'Phone Number is a required field', validate: phoneNumberValidation})} className={errors.phoneNumber && 'inputError'} />
                        {errors.phoneNumber && errorField(errors.phoneNumber.message)}
                    </section>

                    <section>
                        <label>Email Address*</label>
                        <input {...register("email", {required: 'Email is a required field', validate: value => value.includes('@') ? true : "A valid email is required"})} className={errors.email && 'inputError'} />
                        {errors.email && errorField(errors.email.message)}
                    </section>

                    <section>
                        <label>Test Result*</label>
                        <select {...register("result", {required: 'Test Result is a required field'})} className={errors.result && 'inputError'}>
                            <option value={"1"}>Positive</option>
                            <option value={"2"}>Negative</option>
                            <option value={"12"}>Inconclusive</option>
                            <option value={"13"}>Void</option>
                        </select>
                        {errors.result && errorField(errors.result.message)}
                    </section>

                    <section>
                        <label>Specimen ID / Number</label>
                        <input {...register("specimenID")} className={errors.specimenID && 'inputError'} />
                        {errors.specimenID && errorField(errors.specimenID.message)}
                    </section>

                    <section>
                        <label>Specimen Date*</label>
                        <Controller
                            control={control}
                            name="specimenDate"
                            rules={{required: 'Specimen Date is a required field'}}
                            render={({field}) => (
                                <ReactDatePicker
                                    className={errors.specimenDate && 'inputError'}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={field.onChange}
                                    selected={field.value}
                                />
                            )}
                        />
                        {errors.specimenDate && errorField(errors.specimenDate.message)}
                    </section>

                    <section>
                        <label>Specimen Type*</label>
                        <select {...register("specimenType", {required: 'Specimen Type is a required field'})} className={errors.specimenType && 'inputError'}>
                            <option value={SpecimenType.Nose}>Nose</option>
                            <option value={SpecimenType.Throat}>Throat</option>
                            <option value={SpecimenType.Saliva}>Saliva</option>
                            <option value={SpecimenType.NoseThroat}>Nose & Throat</option>
                            <option value={SpecimenType.Mouth}>Mouth</option>
                            <option value={SpecimenType.UpperRespiratory}>Upper Respiratory</option>
                            <option value={SpecimenType.Other}>Other</option>
                        </select>
                        {errors.specimenType && errorField(errors.specimenType.message)}
                    </section>

                    <section>
                        <label>Test Requesting Organisation*</label>
                        <select {...register("testRequestingOrganisation", {required: 'Test Requesting Organisation is a required field'})} className={errors.testRequestingOrganisation && 'inputError'}>
                            <option value={"Environmental Health Officer"}>Environmental Health Officer</option>
                            <option value={"General Practitioner"}>General Practitioner</option>
                            <option value={"Genito-Urinary Medicine Dept"}>Genito-Urinary Medicine Dept</option>
                            <option value={"Hospital Inpatient"}>Hospital Inpatient</option>
                            <option value={"Hospital A&E"}>Hospital A&E</option>
                            <option value={"Hospital Outpatient"}>Hospital Outpatient</option>
                            <option value={"Occupational Health Dept"}>Occupational Health Dept</option>
                            <option value={"Educational Establishment"}>Educational Establishment</option>
                            <option value={"Her Majestys Prison"}>Her Majestys Prison</option>
                            <option value={"Armed Forces"}>Armed Forces</option>
                            <option value={"Healthcare Worker Testing"}>Healthcare Worker Testing</option>
                            <option value={"Care Home"}>Care Home</option>
                            <option value={"Local Authority"}>Local Authority</option>
                            <option value={"Third Party Provider"}>Third Party Provider</option>
                            <option value={"Other"}>Other</option>
                            <option value={"Unknown"}>Unknown</option>
                        </select>
                        {errors.testRequestingOrganisation && errorField(errors.testRequestingOrganisation.message)}
                    </section>

                    <section>
                        <label>Manufacturer of Test*</label>
                        <select {...register("manufacturerOfTest", {required: 'Manufacturer of Test is a required field'})} className={errors.manufacturerOfTest && 'inputError'}>
                            <option value={"speedyChecks"}>Speedychecks</option>
                        </select>
                        {errors.manufacturerOfTest && errorField(errors.manufacturerOfTest.message)}
                    </section>

                    <section>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? "reCAPTCHA_key_not_found"}
                            onChange={() => setRecaptchaValid(true)}
                        />
                    </section>

                    <section>
                        <input type="submit" className={isValid ? 'submit-success' : 'submit-disabled'} disabled={submitting} />
                        {errors.submitError && <p className={"error-message"}>{errors.submitError.message}</p>}
                    </section>
                </div>
            </form>
        </>
    )
}

export default SelfReportingForm;