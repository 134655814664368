import {FAQ} from "../../data/FAQs";

import classes from "./FAQ.module.scss";

const FAQBlock = (props: FAQ) => {
    const { question, answer } = props;
    
    const answerNode = !Array.isArray(answer)
        ? <p>{answer}</p>
        : answer.map((a) => <p>{a}</p>);
        
        return (
            <dl className={classes.FAQ}>
                <dt>{question}</dt>
                <dd>{answerNode}</dd>
            </dl>
        );
}

export default FAQBlock;
